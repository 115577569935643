import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Link } from "../Link";

const axes = {
  influence: {
    name: "Influence",
    path: "overview#influence-axis",
    levels: [
      "PROJECT: Makes an impact on one or more projects or products.",
      "TEAM: Makes an impact on the whole team, not just on specific parts of the team.",
      "MULTIPLE TEAMS: Makes an impact not only on their team but also on other teams.",
      "COMMUNITY: In addition to impacting their program wide teams, they also make an impact on the greater Human Centered Design community, including within the company and client space",
      "COMPANY: Makes an impact on the whole organization.",
    ],
  },
  people: {
    name: "People",
    path: "overview#people-axis",
    levels: [
      "LEARNS: Quickly learns from others and consistently steps up when it is required of them.",
      "SUPPORTS: Proactively supports others and helps them to be successful",
      "MENTORS: Mentors others to accelerate their career growth and encourages them to participate.",
      "COORDINATES: Coordinates activities of others, providing effective feedback and moderating discussions.",
      "MANAGES: Manages the career, expectations, performance, and level",
    ],
  },
  domain: {
    name: "Domain",
    path: "overview#domain-axis",
    levels: [
      "ADOPTS: Actively learns, adopts, and analyzes the domain area for a given business/product.",
      "SPECIALIZES: Is particularly skilled in one or more domain areas for a given business/product.",
      "EVANGELIZES: Researches, creates proofs of concept, and introduces new domain areas for a given business/product.",
      "MASTERS: Has very deep knowledge about all domain areas for a given business/product.",
      "CREATES: Identifies, establishes, and matures new domain areas for new businesses/products.",
    ],
  },
  process: {
    name: "Process",
    path: "overview#process-axis",
    levels: [
      "FOLLOWS: Follows established processes.",
      "ENFORCES: Enforces established processes, making sure others understand the benefits and tradeoffs.",
      "CHALLENGES: Challenges established processes, looking for ways to improve them.",
      "ADJUSTS: Adjusts established processes, listening to feedback and guiding the team through the changes.",
      "DEFINES: Defines processes, balancing agility and discipline.",
    ],
  },
  discipline: {
    name: "Discipline",
    path: "overview#discipline-axis",
    levels: [
      "UNDERSTANDING: Has basic understanding of the principles of design and is able to distinguish between good experience design and bad experience design",
      "EVOLVED: Has a solid foundation of design principles and a basic understanding of the design and development process.",
      "ADVANCING METHODS: Pushes themselves outside of their comfort zones to apply methods and approaches in novel and unexpected ways, while adhering to best practices.",
      "LEADING: Develops problem-framing approaches, establishing new starting points that lead to new kinds of solutions with an impact strategic direction.",
      "STRATEGIZE & EDUCATES: Uses strategic methods, not as a tool to solve execution problems, but to identify new opportunities for value creation.",
    ],
  },
  systemicAnalysis: {
    name: "Systemic Analysis",
    path: "overview#systemic-analysis-axis",
    levels: [
      "DESCRIBES: Has a natural curiosity that drives them. Can intuitively identify issues with designs or experiences but may not know why or have a sound solution.Applies basic service design techniques to understand current state performance, pain points, etc",
      "EXPANDS: Evolves the understanding of the why around the problem. Assesses and analyzes the current state user and system workflows and user journeys. Starting to connect the dots based on UX best practices, design principles, and applied psychology",
      "CONNECTS: Starts to broaden their approach to take on a systems way of thinking. Understands how the dots connect and what relationships are required to solve the problem",
      "DEEPENS: Understands there are multiple solutions to solve the problem and thinks in terms of scalable solutions. Breaks solutions or plans into systematic and strategic steps. Grasps the overlapping problems and solutions and how they all map together. Able to assess system, resource, and scope constraints to propose appropriate solutions.",
      "ADVANCES: Assess and analyze disparate pieces of quantitative and qualitative data to answer the question “What should be done?” They have a strong ability to identify opportunities that are well beyond the scope of their existing work. They can see beyond the current state workflow and journey to identify additional opportunities to improve the business, experience, process, and inefficiencies.Ability to identify the appropriate metrics to measure success.",
    ],
  },
  stakeholderManagement: {
    name: "Stakeholder Management",
    path: "overview#stakeholder-management-axis",
    levels: [
      "FOUNDATIONAL: Takes initiative to understand stakeholder roles and stakeholder objectives, their needs, and understanding of their perspectives.",
      "GAINING TRUST: Works with leadership to gain stakeholder trust and confidence",
      "FACILITATION: Assists in discussions with, and management of key stakeholders",
      "CONSULTANT: Seen as a trusted consultant to a few key stakeholders. Leads and facilitates discussions with stakeholders throughout the project lifecycle to reach alignment. They are great at translating general knowledge into a specific solution, whether that means creating a winning product solution or finding a new vendor for your software systems.",
    ],
  },
};

const tracks = {
  individualContributor: {
    name: "IC",
    path: "overview#individual-contributor",
  },
  lead: {
    name: "Lead",
    path: "overview#lead-track",
  },
  management: {
    name: "Management",
    path: "overview#management-track",
  },
};

const positions = {
  hcd: {
    ux: [
      {
        name: "UX Designer I",
        track: tracks.individualContributor,
        influence: 0,
        people: 0,
        domain: 0,
        process: 0,
        discipline: 0,
        systemicAnalysis: 0,
        stakeholderManagement: 0,
      },
      {
        name: "UX Designer II",
        track: tracks.individualContributor,
        influence: 0,
        people: 1,
        domain: 1,
        process: 0,
        discipline: 1,
        systemicAnalysis: 1,
        stakeholderManagement: 1,
      },
      {
        name: "Senior UX Designer",
        track: tracks.individualContributor,
        influence: 1,
        people: 2,
        domain: 2,
        process: 1,
        discipline: 2,
        systemicAnalysis: 2,
        stakeholderManagement: 2,
      },
      {
        name: "UX Design Lead",
        track: tracks.lead,
        influence: 2,
        people: 3,
        domain: 3,
        process: 2,
        discipline: 3,
        systemicAnalysis: 3,
        stakeholderManagement: 2,
      },
    ],
    userResearch: [
      {
        name: "User Researcher I",
        track: tracks.individualContributor,
        influence: 0,
        people: 0,
        domain: 0,
        process: 0,
        discipline: 0,
        systemicAnalysis: 0,
        stakeholderManagement: 0,
      },
      {
        name: "User Researcher II",
        track: tracks.individualContributor,
        influence: 0,
        people: 1,
        domain: 1,
        process: 0,
        discipline: 1,
        systemicAnalysis: 1,
        stakeholderManagement: 1,
      },
      {
        name: "Senior User Researcher",
        track: tracks.individualContributor,
        influence: 1,
        people: 2,
        domain: 2,
        process: 1,
        discipline: 2,
        systemicAnalysis: 2,
        stakeholderManagement: 2,
      },
      {
        name: "User Research Lead",
        track: tracks.lead,
        influence: 2,
        people: 3,
        domain: 3,
        process: 2,
        discipline: 3,
        systemicAnalysis: 3,
        stakeholderManagement: 2,
      },
    ],
    serviceDesign: [
      {
        name: "Service Designer",
        track: tracks.individualContributor,
        influence: 1,
        people: 1,
        domain: 1,
        process: 1,
        discipline: 1,
        systemicAnalysis: 2,
        stakeholderManagement: 1,
      },
      {
        name: "Senior Service Designer",
        track: tracks.individualContributor,
        influence: 2,
        people: 2,
        domain: 2,
        process: 2,
        discipline: 2,
        systemicAnalysis: 3,
        stakeholderManagement: 2,
      },
      {
        name: "Service Design Lead (aka HCD Lead)",
        track: tracks.lead,
        influence: 3,
        people: 3,
        domain: 3,
        process: 3,
        discipline: 3,
        systemicAnalysis: 4,
        stakeholderManagement: 3,
      },
      {
        name: "Director of HCD",
        track: tracks.management,
        influence: 4,
        people: 4,
        domain: 4,
        process: 4,
        discipline: 4,
        systemicAnalysis: 4,
        stakeholderManagement: 3,
      },
    ],
  },
};

const BorderedTableCell = styled(TableCell)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

function RolesAndTracksTable(): JSX.Element {
  return (
    <TableContainer sx={{ mb: 3 }} component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <BorderedTableCell align="center" variant="head">
              Design
            </BorderedTableCell>
            <BorderedTableCell align="center" variant="head">
              Research
            </BorderedTableCell>
            <BorderedTableCell align="center" variant="head">
              Service Design
            </BorderedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="management">
            <TableCell variant="head" align="center">
              Management
            </TableCell>
            <BorderedTableCell
              colSpan={3}
              align="center"
              sx={{ paddingRight: 6 }}
            >
              Director of HCD
            </BorderedTableCell>
          </TableRow>
          <TableRow key="lead">
            <TableCell variant="head" align="center">
              Lead
            </TableCell>
            <BorderedTableCell align="center">UX Design Lead</BorderedTableCell>
            <BorderedTableCell align="center">
              User Research Lead
            </BorderedTableCell>
            <BorderedTableCell align="center">
              Service Design Lead
            </BorderedTableCell>
          </TableRow>
          <TableRow
            key="ic"
            // sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}
          >
            <TableCell
              rowSpan={4}
              variant="head"
              align="center"
              sx={{ borderBottom: 0 }}
            >
              Individual Contributor (IC)
            </TableCell>
          </TableRow>
          <TableRow key="ic3">
            <BorderedTableCell align="center">
              Senior UX Designer
            </BorderedTableCell>
            <BorderedTableCell align="center">
              Senior User Researcher
            </BorderedTableCell>
            <BorderedTableCell align="center">
              Senior Service Designer
            </BorderedTableCell>
          </TableRow>
          <TableRow key="ic2">
            <BorderedTableCell align="center">UX Designer II</BorderedTableCell>
            <BorderedTableCell align="center">
              User Researcher II
            </BorderedTableCell>
            <BorderedTableCell align="center">
              Service Designer (II)
            </BorderedTableCell>
          </TableRow>
          <TableRow
            key="ic1"
            sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}
          >
            <BorderedTableCell align="center">UX Designer I</BorderedTableCell>
            <BorderedTableCell align="center">
              User Researcher I
            </BorderedTableCell>
            <BorderedTableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function JobLadderOverviewTable(props): JSX.Element {
  const { discipline, subDiscipline } = props;
  return (
    <TableContainer sx={{ mb: 3 }} component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            {positions[discipline][subDiscipline].map((position) => (
              <TableCell align="left" style={{ verticalAlign: "top" }}>
                {position.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key="track"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Link to="overview#tracks">Track</Link>
            </TableCell>
            {positions[discipline][subDiscipline].map((position) => (
              <TableCell align="left">
                <Link to={position.track.path}>{position.track.name}</Link>
              </TableCell>
            ))}
          </TableRow>
          {Object.keys(axes).map((axis) => (
            <TableRow
              key={axis}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ verticalAlign: "top" }}
              >
                <Link to={axes[axis].path}>{axes[axis].name}</Link>
              </TableCell>
              {positions[discipline][subDiscipline].map((position) => (
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  {axes[axis].levels[position[axis]]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { JobLadderOverviewTable, RolesAndTracksTable };
