import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { styled, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { css } from "@emotion/react";
import { Link } from "../Link";
import { prismMaterialLight, prismOkaidia } from "./Prism";
import { RadialChart } from "../RadialChart";
import { LevelsAndTracksTable } from "./LevelsAndTracksTable";
import { TimeAllocationTable } from "./TimeAllocationTable";
import {
  JobLadderOverviewTable,
  RolesAndTracksTable,
} from "./JobLadderOverviewTable";

const StyledDiv = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  "&:hover .anchor.after": {
    display: "inline-block",
    padding: "0 8px",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "& svg": {
      width: "0.7em",
      height: "0.7em",
      fill: "currentColor",
    },
  },
  "& p > span.gatsby-resp-image-wrapper": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[12],
  },
  "& .gatsby-highlight, img, table": {
    maxWidth: `calc(100vw - ${theme.spacing(4)})`,
  },
  "& td > ul": {
    paddingInlineStart: theme.spacing(2),
  },
}));

const MarkdownWrapper = (props) => {
  const theme = useTheme();
  const {
    palette: { mode },
  } = theme;
  const base = mode === "dark" ? prismOkaidia : prismMaterialLight;
  const backgroundColor =
    theme.palette.mode === "dark" ? "#272c34" : theme.palette.background.paper;
  const inlineCodeBoxShadow = mode === "dark" ? "none" : theme.shadows[1];
  const styles = css`
    ${base}
    code[class*="language-"] {
      font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
      color: ${theme.palette.text.primary};
      font-size: 0.8em;
      background: ${backgroundColor};
      border-radius: ${theme.shape.borderRadius}px;
      box-shadow: ${inlineCodeBoxShadow};
      padding: 0.2em 0.4em;
    }

    pre[class*="language-"] > code[class*="language-"] {
      font-size: 1em;
      background: transparent;
      padding: ${theme.spacing(0)};
      box-shadow: none;
    }

    /**
     * Add back the container background-color, border-radius, padding, margin
     * and overflow that we removed from <pre>.
     */
    .gatsby-highlight {
      background-color: ${backgroundColor};
      border-radius: ${theme.shape.borderRadius}px;
      margin: ${theme.spacing(2, 0, 4, 0)};
      padding: ${theme.spacing(3)};
      overflow: auto;
      box-shadow: ${theme.shadows[8]};
    }

    /**
     * Remove the default PrismJS theme background-color, border-radius, margin,
     * padding and overflow.
     * 1. Make the element just wide enough to fit its content.
     * 2. Always fill the visible space in .gatsby-highlight.
     * 3. Adjust the position of the line numbers
     */
    .gatsby-highlight pre[class*="language-"] {
      background-color: transparent;
      margin: 0;
      padding: 0;
      overflow: initial;
      float: left; /* 1 */
      min-width: 100%; /* 2 */
    }
  `;
  return <StyledDiv css={styles} {...props} />;
};

const MarkdownBlockquote = styled("blockquote")(({ theme }) => ({
  ...theme.typography.body2,
  paddingLeft: ".6rem",
  color: theme.palette.text.secondary,
  borderLeft: `.2rem solid ${theme.palette.divider}`,
  margin: "1em 0",
  fontStyle: "italic",
}));

function StyledLink(props) {
  const { href } = props;
  return <Link underline="none" to={href} {...props} />;
}

function StyledTh(props) {
  const { children } = props;
  return (
    <TableCell component="th" scope="row">
      {children}
    </TableCell>
  );
}

function StyledTableCell(props) {
  const { children, align } = props;
  if (typeof children === "string") {
    return <TableCell align={align || "inherit"}>{children}</TableCell>;
  }
  return <TableCell>{children}</TableCell>;
}

function StyledTableRow(props) {
  return <TableRow sx={{ "&:last-child td": { border: 0 } }} {...props} />;
}

function StyledTable(props) {
  return (
    <TableContainer component={Paper} elevation={12} sx={{ mt: 2, mb: 2 }}>
      <Table {...props} />
    </TableContainer>
  );
}

function StyledTableHead(props) {
  return <TableHead {...props} />;
}

function StyledTableBody(props) {
  return <TableBody {...props} />;
}

const MarkdownH1 = (props) => (
  <Typography variant="h1" component="h1" sx={{ mt: 2, mb: 2 }} {...props} />
);

const MarkdownH2 = (props) => (
  <Box sx={{ mt: 5, mb: 1 }}>
    <Typography variant="h2" component="h2" sx={{ mb: 1 }} {...props} />
    <Divider />
  </Box>
);

const MarkdownH3 = (props) => (
  <Typography variant="h3" component="h3" sx={{ mt: 5, mb: 1 }} {...props} />
);

const MarkdownH4 = (props) => (
  <Typography variant="h4" component="h4" sx={{ mt: 2, mb: 1 }} {...props} />
);

const MarkdownH5 = (props) => (
  <Typography variant="h5" component="h5" sx={{ mt: 1, mb: 0 }} {...props} />
);

const MarkdownH6 = (props) => (
  <Typography variant="h6" component="h6" sx={{ mt: 1, mb: 0 }} {...props} />
);

const MarkdownP = (props) => (
  <Typography variant="body1" component="p" sx={{ mb: 2 }} {...props} />
);

const EngineeringPositionRadialChart = (props) => {
  const { data, showLevelLabels } = props;
  return (
    <RadialChart
      width={800}
      height={850}
      padding={100}
      domainMax={5}
      showLevelLabels={showLevelLabels}
      data={{
        variables: [
          {
            key: "influence",
            label: "Influence",
            levelLabels: [
              "Project",
              "Team",
              "Multiple Teams",
              "Company",
              "Community",
            ],
          },
          {
            key: "technology",
            label: "Technology",
            levelLabels: [
              "Adopts",
              "Specializes",
              "Evangelizes",
              "Masters",
              "Creates",
            ],
          },
          {
            key: "system",
            label: "System",
            levelLabels: ["Enhances", "Designs", "Owns", "Evolves", "Leads"],
          },
          {
            key: "people",
            label: "People",
            levelLabels: [
              "Learns",
              "Supports",
              "Mentors",
              "Coordinates",
              "Manages",
            ],
          },
          {
            key: "process",
            label: "Process",
            levelLabels: [
              "Follows",
              "Enforces",
              "Challenges",
              "Adjusts",
              "Defines",
            ],
          },
        ],
        sets: data,
      }}
    />
  );
};

const DataSciencePositionRadialChart = (props) => {
  const { data, showLevelLabels } = props;
  return (
    <RadialChart
      width={800}
      height={850}
      padding={100}
      domainMax={5}
      showLevelLabels={showLevelLabels}
      data={{
        variables: [
          {
            key: "influence",
            label: "Influence",
            levelLabels: [
              "Project",
              "Team",
              "Multiple Teams",
              "Company",
              "Community",
            ],
          },
          {
            key: "people",
            label: "People",
            levelLabels: [
              "Learns",
              "Supports",
              "Mentors",
              "Coordinates",
              "Manages",
            ],
          },
          {
            key: "process",
            label: "Process",
            levelLabels: [
              "Follows",
              "Enforces",
              "Challenges",
              "Adjusts",
              "Defines",
            ],
          },
          {
            key: "domain",
            label: "Domain",
            levelLabels: [
              "Adopts",
              "Specializes",
              "Evangelizes",
              "Masters",
              "Creates",
            ],
          },
          {
            key: "analytics",
            label: "Analytics",
            levelLabels: [
              "Descriptive",
              "Diagnostic",
              "Predictive",
              "Prescriptive",
            ],
          },
        ],
        sets: data,
      }}
    />
  );
};

const HcdPositionRadialChart = (props) => {
  const { data, showLevelLabels } = props;
  return (
    <RadialChart
      width={800}
      height={850}
      padding={100}
      domainMax={5}
      showLevelLabels={showLevelLabels}
      data={{
        variables: [
          {
            key: "influence",
            label: "Influence",
            levelLabels: [
              "Project",
              "Team",
              "Multiple Teams",
              "Company",
              "Community",
            ],
          },
          {
            key: "domain",
            label: "Domain",
            levelLabels: [
              "Adopts",
              "Specializes",
              "Evangelizes",
              "Masters",
              "Creates",
            ],
          },
          {
            key: "analytics",
            label: "Analytics",
            levelLabels: [
              "Descriptive",
              "Diagnostic",
              "Predictive",
              "Prescriptive",
            ],
          },
          {
            key: "people",
            label: "People",
            levelLabels: [
              "Learns",
              "Supports",
              "Mentors",
              "Coordinates",
              "Manages",
            ],
          },
          {
            key: "process",
            label: "Process",
            levelLabels: [
              "Follows",
              "Enforces",
              "Challenges",
              "Adjusts",
              "Defines",
            ],
          },
        ],
        sets: data,
      }}
    />
  );
};

const ProductPositionRadialChart = (props) => {
  const { data, showLevelLabels } = props;
  return (
    <RadialChart
      width={800}
      height={850}
      padding={100}
      domainMax={5}
      showLevelLabels={showLevelLabels}
      data={{
        variables: [
          {
            key: "communication",
            label: "Communication",
            levelLabels: [
              "Empathizing",
              "Influencing",
              "Winning",
              "Coaching",
              "Leading",
            ],
          },
          {
            key: "leadership",
            label: "Leadership",
            levelLabels: ["Responsible", "Owner", "Coach", "Leader"],
          },
          {
            key: "delivery",
            label: "Delivery",
            levelLabels: ["Coordinates", "Manages", "Oversees"],
          },
          {
            key: "design-thinking",
            label: "Design Thinking",
            levelLabels: ["Practices", "Collaborates", "Augments"],
          },
          {
            key: "research",
            label: "Research",
            levelLabels: ["Seeks", "Collaborates", "Augments"],
          },
          {
            key: "engineering",
            label: "Engineering",
            levelLabels: ["Defers", "Practices", "Collaborates"],
          },
          {
            key: "strategy",
            label: "Strategy",
            levelLabels: [
              "Team Contributor",
              "Product Shaper",
              "Portfolio Guide",
              "Culture Builder",
              "Market Leader",
            ],
          },
        ],
        sets: data,
      }}
    />
  );
};

const components = {
  wrapper: MarkdownWrapper,
  h1: MarkdownH1,
  h2: MarkdownH2,
  h3: MarkdownH3,
  h4: MarkdownH4,
  h5: MarkdownH5,
  h6: MarkdownH6,
  p: MarkdownP,
  a: StyledLink,
  table: StyledTable,
  thead: StyledTableHead,
  tbody: StyledTableBody,
  tr: StyledTableRow,
  td: StyledTableCell,
  th: StyledTh,
  blockquote: MarkdownBlockquote,
  hr: Divider,
  EngineeringPositionRadialChart,
  DataSciencePositionRadialChart,
  HcdPositionRadialChart,
  ProductPositionRadialChart,
  LevelsAndTracksTable,
  TimeAllocationTable,
  JobLadderOverviewTable,
  RolesAndTracksTable,
};

const MdxContent: React.FC = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
);

export { MdxContent };
