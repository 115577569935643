import * as React from "react";
import { useLocation, Redirect } from "@reach/router";
import { useAuth } from "./AuthProvider";
import { Login } from "./Login";
import { Loading } from "./Loading";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = React.useState<
    string | null
  >(null);

  if (!auth.isInitialized) {
    return <Loading />;
  }
  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Redirect to={requestedLocation} noThrow />;
  }

  return <>{children}</>;
};

export default AuthGuard;
