import * as React from "react";
import {
  Box,
  Stack,
  styled,
  Table,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  darken,
} from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { Link } from "../Link";

// TODO: refactor this implementation to accept the raw content as input, i.e. externalize the data such that we can utilize this table visualization for all disciplines

const EmptyTableCell = styled(MuiTableCell)(() => ({
  border: "none",
  height: 75,
  width: 120,
}));

const TableCell = styled(EmptyTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  fontSize: "0.8rem",
}));

const LinkTableCell = styled(TableCell)(() => ({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
    opacity: 0.8,
  },
}));

const TableBody = styled(MuiTableBody)(({ theme }) => ({
  ...theme.typography.body2,
}));

function LevelsAndTracksTable(): JSX.Element {
  const theme = useTheme();
  const colors = {
    backgroundColor: {
      header:
        theme.palette.mode === "dark"
          ? theme.palette.info.dark
          : theme.palette.info.light,
      ux: theme.palette.mode === "dark" ? darken("#92D3E8", 0.2) : "#92D3E8",
      research:
        theme.palette.mode === "dark"
          ? theme.palette.success.dark
          : theme.palette.success.light,
      serviceDesign:
        theme.palette.mode === "dark" ? darken("#BFA7E4", 0.4) : "#BFA7E4",
      vp: theme.palette.mode === "dark" ? darken("#9DBCC6", 0.2) : "#9DBCC6",
    },
  };
  return (
    <Box sx={{ width: 1250 }}>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: { lg: "visible" },
          mx: { lg: -28 },
        }}
      >
        <Table
          size="small"
          sx={{
            borderCollapse: "separate",
            borderSpacing: `${theme.spacing(1)} ${theme.spacing(1)}`,
          }}
        >
          <TableHead>
            <TableRow>
              <EmptyTableCell />
              <TableCell
                align="center"
                colSpan={9}
                variant="head"
                sx={{
                  backgroundColor: "unset",
                  boxShadow: "unset",
                  verticalAlign: "bottom",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <KeyboardArrowLeft />
                  To move over (and then up) you need to grow in your leadership
                  and management skills
                  <KeyboardArrowRight />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <EmptyTableCell />
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  backgroundColor: colors.backgroundColor.header,
                }}
              >
                Individual Contributor
                <Box sx={{ ...theme.typography.caption }}>
                  100% Individual Contribution
                  <br />
                  0% People Management
                </Box>
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  backgroundColor: colors.backgroundColor.header,
                }}
              >
                Lead
                <Box
                  sx={{
                    ...theme.typography.caption,
                  }}
                >
                  50% Individual Contribution
                  <br />
                  50% People Management
                </Box>
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  backgroundColor: colors.backgroundColor.header,
                }}
              >
                Management
                <Box sx={{ ...theme.typography.caption }}>
                  0% Individual Contribution
                  <br />
                  100% People Management
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                rowSpan={7}
                variant="head"
                sx={{
                  width: "50px",
                  backgroundColor: "unset",
                  boxShadow: "unset",
                }}
              >
                <Stack justifyContent="center" alignItems="center">
                  <KeyboardArrowUp />
                  To move up, one must grow in maturity within their discipline
                </Stack>
              </TableCell>
              <EmptyTableCell colSpan={6} />
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  backgroundColor: colors.backgroundColor.vp,
                }}
              >
                Director of HCD
              </TableCell>
            </TableRow>
            <TableRow>
              <EmptyTableCell colSpan={6} />
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.ux}`,
                }}
              >
                Director of UX Design
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.research}`,
                }}
              >
                Director of Research
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.serviceDesign}`,
                }}
              >
                Director of Service Design and Innovation
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.ux}`,
                }}
              >
                Principal Designer
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.research}`,
                }}
              >
                Principal Design Researcher
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.serviceDesign}`,
                }}
              >
                Principal Service Designer
              </TableCell>
              <EmptyTableCell colSpan={2} />
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/service-design-path#service-design-strategy-lead"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.serviceDesign }}
              >
                Service Design Strategy Lead
              </LinkTableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.ux}`,
                }}
              >
                UX Design Manager
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.research}`,
                }}
              >
                Research Manager
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: theme.palette.text.disabled,
                  backgroundColor: "unset",
                  border: `2px dashed ${colors.backgroundColor.serviceDesign}`,
                }}
              >
                Service Design Manager
              </TableCell>
            </TableRow>
            <TableRow>
              <EmptyTableCell colSpan={2} />
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/service-design-path#senior-service-design-strategist"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.serviceDesign }}
              >
                Senior Service Design Strategist
              </LinkTableCell>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/ux-design-path#ux-design-lead"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.ux }}
              >
                UX Design Lead
              </LinkTableCell>
              <LinkTableCell
                component={Link}
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.research }}
                to="/employee-success/career-frameworks/human-centered-design/design-research-path#research-lead"
              >
                Research Lead
              </LinkTableCell>
              <EmptyTableCell />
              <TableCell
                align="center"
                colSpan={3}
                variant="head"
                sx={{
                  backgroundColor: "unset",
                  boxShadow: "unset",
                  verticalAlign: "top",
                }}
              >
                The above manage across contracts - based on industry
              </TableCell>
            </TableRow>
            <TableRow>
              <LinkTableCell
                component={Link}
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.ux }}
                to="/employee-success/career-frameworks/human-centered-design/ux-design-path#senior-ux-designer"
              >
                Senior UX Designer
              </LinkTableCell>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/design-research-path#senior-design-researcher"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.research }}
              >
                Senior Design Researcher
              </LinkTableCell>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/service-design-path#service-design-strategist"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.serviceDesign }}
              >
                Service Design Strategist
              </LinkTableCell>
              <EmptyTableCell colSpan={6} />
            </TableRow>
            <TableRow>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/ux-design-path#ux-designer-ii"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.ux }}
              >
                UX Designer II
              </LinkTableCell>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/design-research-path#design-researcher-ii"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.research }}
              >
                Design Researcher II
              </LinkTableCell>
              <EmptyTableCell colSpan={7} />
            </TableRow>
            <TableRow>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/ux-design-path#ux-designer-i"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.ux }}
              >
                UX Designer I
              </LinkTableCell>
              <LinkTableCell
                component={Link}
                to="/employee-success/career-frameworks/human-centered-design/design-research-path#design-researcher-i"
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.research }}
              >
                Design Researcher I
              </LinkTableCell>
              <EmptyTableCell colSpan={7} />
            </TableRow>
            <TableRow>
              <EmptyTableCell colSpan={1} />
              <TableCell
                align="center"
                colSpan={3}
                variant="head"
                sx={{
                  backgroundColor: "unset",
                  boxShadow: "unset",
                  verticalAlign: "top",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <KeyboardArrowLeft />
                  To move to another discipline, one must learn the necessary
                  skills
                  <KeyboardArrowRight />
                </Stack>
              </TableCell>
              <EmptyTableCell colSpan={3} />
            </TableRow>
            <TableRow>
              <EmptyTableCell />
              <TableCell
                align="center"
                colSpan={9}
                sx={{
                  backgroundColor: "unset",
                  boxShadow: "unset",
                  verticalAlign: "top",
                  color: theme.palette.text.secondary,
                }}
              >
                Positions with dotted borders do not exist yet.
              </TableCell>
              <EmptyTableCell colSpan={3} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export { LevelsAndTracksTable };
