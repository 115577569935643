import * as React from "react";

interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  redirectPathname: string | null;
  user: User | null;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  redirectPathname: null,
};

type AuthContextValue = State & {
  platform: "Amplify";
  login: (redirectPathname: string) => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContextValue>({
  ...initialState,
  platform: "Amplify",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export default AuthContext;
export { initialState, User, AuthContextValue };
