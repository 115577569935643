import React from "react";
import { Link, GatsbyLinkProps } from "gatsby";

interface ALinkProps extends Omit<GatsbyLinkProps<unknown>, "to"> {
  href: string;
}

const ALink: React.FC<ALinkProps> = ({
  href,
  children,
  innerRef,
  ...other
}) => (
  <a href={href} ref={innerRef} {...other}>
    {children}
  </a>
);

const GatsbyLink = React.forwardRef(
  (
    props: Omit<GatsbyLinkProps<unknown>, "ref">,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const { to, activeClassName, partiallyActive, ...other } = props;
    const internal = /^(\/|#)(?!\/)/.test(to);

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      const anchor = /^#/.test(to);
      const file = /\.[0-9a-z]+$/i.test(to);

      if (file || anchor) {
        return <ALink href={to} innerRef={ref} {...other} />;
      }
      return (
        <Link
          to={to}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          innerRef={ref}
          {...other}
        />
      );
    }
    return (
      <ALink
        href={to}
        innerRef={ref}
        target="_blank"
        rel="noopener noreferrer"
        {...other}
      />
    );
  }
);

export default GatsbyLink;
