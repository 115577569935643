import * as React from "react";
import { connectAutoComplete } from "react-instantsearch-dom";
import { alpha, styled } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import { globalHistory } from "@reach/router";
import { Link } from "../Link";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Autocomplete = connectAutoComplete(
  ({ hits, currentRefinement, refine }) => {
    const [open, setOpen] = React.useState<boolean | null>(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const prevOpen = React.useRef(open);

    function handleRefine(value: string) {
      setOpen(true);
      return refine(value);
    }

    function handleClose(event: React.MouseEvent<Document, MouseEvent>) {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as Node)
      ) {
        return;
      }
      setOpen(false);
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpen(false);
      }
    }

    React.useEffect(
      () =>
        globalHistory.listen(({ action }) => {
          if (action === "PUSH") setOpen(false);
        }),
      [setOpen]
    );

    React.useEffect(() => {
      if (prevOpen.current && !open && anchorRef.current) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            value={currentRefinement}
            onChange={(event) => handleRefine(event.currentTarget.value)}
            ref={anchorRef}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <Box>
          <Popper
            open={open as boolean}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {hits.map((hit) => (
                        <ListItem
                          button
                          key={hit.objectID}
                          component={Link}
                          to={hit.slug}
                        >
                          <ListItemText
                            primary={hit.title}
                            secondary={hit.excerpt}
                          />
                        </ListItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </>
    );
  }
);

export default Autocomplete;
