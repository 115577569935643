import * as React from "react";
import { useTheme, alpha, darken } from "@material-ui/core/styles";
import { lineRadial, curveLinearClosed } from "d3-shape";
import type { TickScale } from "../../types";

type LevelsProps = {
  ticks: Array<number>;
  scale: TickScale;
  sides: number;
};

const Levels: React.FC<LevelsProps> = (props: LevelsProps) => {
  const theme = useTheme();
  const { ticks, scale, sides } = props;

  function polygon(numberOfSides: number, radius: number) {
    const radial = lineRadial()
      .curve(curveLinearClosed)
      .angle((_, i) => (i / numberOfSides) * 2 * Math.PI)
      .radius(() => radius);
    const polygonFunction = function polygonFunction() {
      return radial(Array.from({ length: numberOfSides }));
    };
    polygonFunction.radius = radial.radius;
    polygonFunction.angle = radial.angle;
    return polygonFunction;
  }

  return (
    <g>
      <path
        d={polygon(sides, scale(ticks.length * 1.11))() || undefined}
        key={`${6}`}
        fill="none"
        stroke={
          theme.palette.mode === "dark"
            ? alpha(theme.palette.divider, 1)
            : darken(theme.palette.divider, 1)
        }
        strokeWidth={2}
      />
      {ticks.map((tickValue) => (
        <path
          d={polygon(sides, scale(tickValue))() || undefined}
          key={`${tickValue}`}
          fill="none"
          stroke={theme.palette.divider}
          strokeDasharray="4 2"
        />
      ))}
    </g>
  );
};

export { Levels };
