import * as React from "react";
import { styled } from "@material-ui/core/styles";
import type { TickScale, RadialChartPoint, RadialChartVariable } from "./types";
import { Axis } from "./components/Axis";
import { Shape } from "./components/Shape";
import { Levels } from "./components/Levels";

const RadialChartWrapperContainer = styled("div")(() => ({
  display: "inline-block",
  position: "relative",
  width: "100%",
  paddingBottom: "100%",
  verticalAlign: "middle",
  overflow: "hidden",
}));

const RadialChartWrapperSvg = styled("svg")(() => ({
  display: "inline-block",
  position: "absolute",
  top: 0,
  left: 0,
}));

type RadialChartWrapperProps = {
  variables: Array<RadialChartVariable>;
  width: number;
  height: number;
  padding: number;
  domainMax: number;
  scales: { [variableKey: string]: TickScale };
  backgroundScale: TickScale;
  offsetAngles: { [variableKey: string]: number };
  radius: number;
  regularPoints: Array<{ setKey: string; points: Array<RadialChartPoint> }>;
  colors: { [setKey: string]: string };
  numRings?: number;
  showLevelLabels: boolean;
};

const RadialChartWrapper: React.FC<RadialChartWrapperProps> = (props) => {
  const {
    width,
    height,
    padding,
    scales,
    variables,
    offsetAngles,
    domainMax,
    regularPoints,
    backgroundScale,
    colors,
    numRings = 4,
    showLevelLabels,
  } = props;

  const innerHeight = height - padding * 2;
  const innerWidth = width - padding * 2;
  const ticks = backgroundScale.ticks(numRings).slice(1);

  return (
    <RadialChartWrapperContainer>
      <RadialChartWrapperSvg viewBox={`0 0 ${width} ${height}`}>
        <g transform={`translate(${padding}, ${padding})`}>
          <g transform={`translate(${innerWidth / 2}, ${innerHeight / 2})`}>
            <Levels
              ticks={ticks}
              scale={backgroundScale}
              sides={variables.length}
            />
            {variables.map(({ key, label, levelLabels }) => (
              <Axis
                key={key}
                levelLabels={levelLabels}
                showLevelLabels={showLevelLabels}
                scale={scales[key]}
                offsetAngle={offsetAngles[key]}
                label={label}
                domainMax={domainMax}
              />
            ))}
            {regularPoints.map(({ setKey, points }) => (
              <Shape
                key={setKey}
                points={points}
                scales={scales}
                offsetAngles={offsetAngles}
                color={colors[setKey]}
              />
            ))}
          </g>
        </g>
      </RadialChartWrapperSvg>
    </RadialChartWrapperContainer>
  );
};

export { RadialChartWrapper };
