import React from "react";
import { round } from "lodash";
import { lineRadial, curveLinearClosed } from "d3-shape";
import type { TickScale, RadialChartPoint } from "../../types";

type ShapeProps = {
  points: Array<RadialChartPoint>;
  scales: { [variableKey: string]: TickScale };
  offsetAngles: { [variableKey: string]: number };
  color: string;
};

const Shape: React.FC<ShapeProps> = (props) => {
  const { points, scales, offsetAngles, color } = props;
  const fillOpacity = 0.2;
  const strokeOpacity = 0.7;
  const pointOpacity = 0.7;
  const pointRadius = 3;

  const lineFunction = lineRadial<RadialChartPoint>()
    .radius((point: RadialChartPoint) => scales[point.variableKey](point.value))
    .angle((point: RadialChartPoint) =>
      round(offsetAngles[point.variableKey], 6)
    )
    .curve(curveLinearClosed);

  const pathData = lineFunction(points);

  return (
    <g>
      <path
        d={pathData || undefined}
        fill={color}
        fillOpacity={fillOpacity}
        stroke={color}
        strokeOpacity={strokeOpacity}
      />
      {points.map((point) => (
        <circle
          key={point.key}
          r={pointRadius}
          fill={color}
          stroke={color}
          cx={point.x}
          cy={point.y}
          opacity={pointOpacity}
        />
      ))}
    </g>
  );
};

export { Shape };
