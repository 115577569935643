import * as React from "react";
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import createSvgIcon from "@material-ui/core/utils/createSvgIcon";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import SettingsBrightnessIcon from "@material-ui/icons/SettingsBrightness";
import { useSettings } from "./useSettings";

const AdjustmentsIcon = createSvgIcon(
  <svg viewBox="0 0 20 20">
    <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
  </svg>,
  "Adjustments"
);

const Heading = styled(Typography)({
  margin: "16px 0 8px 5px",
});

const IconToggleButton = styled(ToggleButton)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "& > *": {
    marginRight: "8px",
  },
});

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
});

const SettingsDrawer: React.FC = () => {
  const { settings, saveSettings } = useSettings();
  const [open, setOpen] = React.useState<boolean>(false);
  const [values, setValues] = React.useState(getValues(settings));

  React.useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton onClick={handleOpen} color="inherit">
          <AdjustmentsIcon />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        PaperProps={{
          sx: {
            p: 2,
            width: 352,
          },
        }}
      >
        <Typography color="textPrimary" variant="h6">
          Settings
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Heading>Appearance</Heading>
          <ToggleButtonGroup
            value={values.theme}
            exclusive
            fullWidth
            onChange={(event, value): void => handleChange("theme", value)}
          >
            <IconToggleButton value="LIGHT">
              <Brightness7Icon />

              {` LIGHT`}
            </IconToggleButton>
            <IconToggleButton value="SYSTEM">
              <SettingsBrightnessIcon />
              {` System`}
            </IconToggleButton>
            <IconToggleButton value="DARK">
              <Brightness4Icon />
              {` Dark`}
            </IconToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.roundedCorners}
                color="primary"
                edge="start"
                name="roundedCorners"
                onChange={(event): void =>
                  handleChange("roundedCorners", event.target.checked)
                }
              />
            }
            label={
              <div>
                Rounded Corners
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Increase border radius
                </Typography>
              </div>
            }
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleSave}
            variant="contained"
          >
            Save Settings
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export { SettingsDrawer };
