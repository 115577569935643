import { createSlice } from "@reduxjs/toolkit";

export const themeContextSlice = createSlice({
  name: "themeContext",
  initialState: {
    paletteType: null,
  },
  reducers: {
    updatePaletteType: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.paletteType = action.payload;
    },
  },
});

export const selectPaletteType = (state) => state.themeContext.paletteType;

export const { updatePaletteType } = themeContextSlice.actions;

export default themeContextSlice.reducer;
