import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { graphql, useStaticQuery } from "gatsby";
import { styled } from "@material-ui/core/styles";

const FooterWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

const FooterContent = styled("footer")(({ theme }) => ({
  padding: theme.spacing(3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(8, 0),
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export declare type FooterProps = {
  title: string;
  version: string;
  author: string;
};

export const PureFooter: React.FC<FooterProps> = (props) => {
  const { title, version, author } = props;
  return (
    <FooterWrapper>
      <Divider />
      <Container maxWidth="md">
        <FooterContent>
          <Typography
            sx={{
              marginTop: 1,
            }}
            color="textSecondary"
            variant="body2"
          >
            {" Copyright © "}
            {new Date().getFullYear()} {author}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {title} v{version}
          </Typography>
        </FooterContent>
      </Container>
    </FooterWrapper>
  );
};

type FooterQueryResult = {
  site: {
    siteMetadata: {
      title: string;
      version: string;
      author: string;
    };
  };
};

export const Footer: React.FC = () => {
  const {
    site: {
      siteMetadata: { title, version, author },
    },
  } = useStaticQuery<FooterQueryResult>(graphql`
    query {
      site {
        siteMetadata {
          title
          version
          author
        }
      }
    }
  `);

  return <PureFooter title={title} version={version} author={author} />;
};

export default Footer;
