import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import type { TickScale } from "../../types";

type AxisProps = {
  scale: TickScale;
  offsetAngle: number;
  domainMax: number;
  label: string;
  levelLabels: Array<string>;
  showLevelLabels: boolean;
  axisOverreach?: number;
  labelOverreach?: number;
  axisWidth?: number;
};

const Axis: React.FC<AxisProps> = (props) => {
  const theme = useTheme();
  const {
    scale,
    offsetAngle,
    domainMax,
    label,
    levelLabels,
    showLevelLabels,
    axisOverreach = 1,
    labelOverreach = 1.22,
    axisWidth = 2,
  } = props;
  const xFactor = Math.cos(offsetAngle - Math.PI / 2);
  const yFactor = Math.sin(offsetAngle - Math.PI / 2);

  return (
    <g>
      <line
        x1={0}
        y1={0}
        x2={scale(domainMax * axisOverreach) * xFactor}
        y2={scale(domainMax * axisOverreach) * yFactor}
        stroke={theme.palette.divider}
        strokeWidth={axisWidth}
      />
      <text
        key={label}
        x={scale(domainMax * labelOverreach) * xFactor}
        y={scale(domainMax * labelOverreach) * yFactor}
        fontSize={theme.typography.body2.fontSize}
        fontFamily={theme.typography.fontFamily}
        fill={theme.palette.text.primary}
        textAnchor="middle"
        dy="0.35em"
      >
        {label}
      </text>
      {showLevelLabels &&
        levelLabels.map((levelLabel: string, index: number) => (
          <text
            key={levelLabel}
            x={scale(1 + index) * xFactor}
            y={scale(1 + index) * yFactor}
            fontSize=".625rem"
            fontFamily={theme.typography.fontFamily}
            fill={theme.palette.text.primary}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {levelLabel}
          </text>
        ))}
    </g>
  );
};

export { Axis };
