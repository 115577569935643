import * as React from "react";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import Autocomplete from "./Autocomplete";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID ?? "",
  process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? ""
);

const searchIndex = process.env.GATSBY_ALGOLIA_INDEX_NAME ?? "";

const Search: React.FC = () => (
  <InstantSearch searchClient={searchClient} indexName={searchIndex}>
    <Autocomplete />
  </InstantSearch>
);

export { Search };
