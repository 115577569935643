import * as React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";

const Loading: React.FC = () => (
  <Box sx={{ width: "100%" }}>
    <LinearProgress />
  </Box>
);

export { Loading };
