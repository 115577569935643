import React from "react";
import { Container } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";

const LayoutContainer = styled(Container)(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.spacing(8)})`,
  display: "flex",
  flexDirection: "column",
}));

const ContentContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(8),
  flex: "1 0 auto",
}));

export declare interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => (
  <Navbar>
    <LayoutContainer maxWidth="lg">
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </LayoutContainer>
  </Navbar>
);

export { Layout };
