import { configureStore } from "@reduxjs/toolkit";
import themeContextReducer from "./themeContextSlice";
import app from "./app";

export default configureStore({
  reducer: {
    app,
    themeContext: themeContextReducer,
  },
});
