import * as React from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import amplifyConfig from "../../aws-exports-overrides";
import AuthContext, { User, initialState } from "./AuthContext";

Amplify.configure(amplifyConfig);

type ACTIONTYPE =
  | {
      type: "INITIALIZE";
      payload: {
        isAuthenticated: boolean;
        user: User | null;
      };
    }
  | {
      type: "LOGIN";
      payload: {
        user: User;
      };
    }
  | {
      type: "LOGOUT";
    }
  | {
      type: "REDIRECT";
      payload: {
        redirectPathname: string | null;
      };
    };

function reducer(state: typeof initialState, action: ACTIONTYPE) {
  switch (action.type) {
    case "INITIALIZE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case "REDIRECT": {
      const { redirectPathname } = action.payload;

      return {
        ...state,
        redirectPathname,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        redirectPathname: null,
      };
    default:
      throw new Error();
  }
}

interface AuthProviderProps {
  children: React.ReactNode;
}

interface CognitoUser {
  attributes: {
    name: string;
    email: string;
    picture: string;
    family_name: string;
    given_name: string;
  };
}

const mapCognitoUserToUser = (cognitoUser: CognitoUser): User => ({
  id: cognitoUser.attributes.email,
  name: cognitoUser.attributes.name,
  email: cognitoUser.attributes.email,
  avatar: cognitoUser.attributes.picture,
  lastName: cognitoUser.attributes.family_name,
  firstName: cognitoUser.attributes.given_name,
});

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    function handleEvent(data) {
      switch (data.payload.event) {
        case "signIn":
          Auth.currentAuthenticatedUser().then((cognitoUser) => {
            dispatch({
              type: "LOGIN",
              payload: {
                user: mapCognitoUserToUser(cognitoUser),
              },
            });
          });
          break;
        case "customOAuthState":
          dispatch({
            type: "REDIRECT",
            payload: {
              redirectPathname: data.payload.data,
            },
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(
            "AuthContext:useEffect:handleEvent:default: data: ",
            data
          );
      }
    }

    Hub.listen("auth", handleEvent);

    return () => Hub.remove("auth", handleEvent);
  }, []);

  React.useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const user = mapCognitoUserToUser(cognitoUser);
        if (
          process.env.NODE_ENV === "production" &&
          process.env.GATSBY_PENDO_ENABLED === "true"
        ) {
          try {
            // eslint-disable-next-line no-undef
            pendo.initialize({
              visitor: {
                id: user.id,
                email: user.email,
              },
              account: {
                id: "Bellese",
              },
            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error("error initializing pendo");
          }
        }
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } catch (error) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (redirectPathname: string): Promise<void> => {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: redirectPathname,
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: "LOGOUT",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Amplify",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
