import React from "react";
import { schemeCategory10 } from "d3-scale-chromatic";
import { forEachArray, radarPoints, radiusScales } from "./utils";
import type { RadialChartData } from "./types";
import { RadialChartWrapper } from "./RadialChartWrapper";

type RadialChartProps = {
  data: RadialChartData;
  width: number;
  height: number;
  padding: number;
  domainMax: number;
  showLevelLabels?: boolean;
};

function convertData(props: RadialChartProps) {
  const { data, width, height, padding, domainMax } = props;

  const innerHeight = height - padding * 2;
  const innerWidth = width - padding * 2;
  const radius = Math.min(innerWidth / 2, innerHeight / 2);
  const scales = radiusScales(data.variables, domainMax, radius);
  const angleSliceRadians = (Math.PI * 2) / data.variables.length;
  const offsetAngles: { [key: string]: number } = {};

  forEachArray(data.variables, ({ key }, i) => {
    offsetAngles[key] = angleSliceRadians * i;
  });

  const allPoints = radarPoints(data, scales, offsetAngles);

  return { allPoints, scales, offsetAngles, radius };
}

const RadialChart: React.FC<RadialChartProps> = (props) => {
  const {
    data,
    width,
    height,
    padding,
    domainMax,
    showLevelLabels = true,
  } = props;

  const { allPoints, scales, offsetAngles, radius } = convertData(props);
  const backgroundScale = scales[data.variables[0].key];
  const colors: { [key: string]: string } = {};

  forEachArray(allPoints, ({ setKey }, idx) => {
    colors[setKey] = schemeCategory10[idx];
  });

  return (
    <RadialChartWrapper
      variables={data.variables}
      width={width}
      height={height}
      padding={padding}
      domainMax={domainMax}
      scales={scales}
      backgroundScale={backgroundScale}
      offsetAngles={offsetAngles}
      radius={radius}
      regularPoints={allPoints}
      colors={colors}
      showLevelLabels={showLevelLabels}
    />
  );
};

export { RadialChart };
