import * as React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createCustomTheme } from "../Theme";
import { useSettings } from "../Settings";
import AuthGuard from "../AuthGuard";
import { Layout } from "../Layout";
import { Meta } from "../Meta";
import { MdxContent } from "../MdxContent";

const PageWrapper: React.FC = ({ children }) => {
  const { settings } = useSettings();
  const systemPaletteMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "DARK"
    : "LIGHT";
  const theme = React.useMemo(
    () =>
      createCustomTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme === "SYSTEM" ? systemPaletteMode : settings.theme,
        paletteMode: settings.paletteMode,
      }),
    [
      settings.direction,
      settings.paletteMode,
      settings.responsiveFontSizes,
      settings.roundedCorners,
      settings.theme,
      systemPaletteMode,
    ]
  );
  return (
    <>
      <Meta />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthGuard>
          <Layout>
            <MdxContent>{children}</MdxContent>
          </Layout>
        </AuthGuard>
      </ThemeProvider>
    </>
  );
};

export { PageWrapper };
