import awsmobile from "./aws-exports";

const isBrowser = typeof window !== "undefined";

const { host } = isBrowser ? window.location : { host: null };

if (awsmobile.oauth.redirectSignIn.includes(",")) {
  const filterHost = (url: string) => new URL(url).host === host;
  const redirectSignIn = awsmobile.oauth.redirectSignIn
    .split(",")
    .filter(filterHost)
    .shift();
  if (redirectSignIn) {
    awsmobile.oauth.redirectSignIn = redirectSignIn;
  }
  const redirectSignOut = awsmobile.oauth.redirectSignOut
    .split(",")
    .filter(filterHost)
    .shift();
  if (redirectSignOut) {
    awsmobile.oauth.redirectSignOut = redirectSignOut;
  }
}
export default awsmobile;
