import * as React from "react";
import {
  Box,
  Stack,
  styled,
  Table,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  darken,
} from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";

// TODO: refactor this implementation to accept the raw content as input, i.e. externalize the data such that we can utilize this table visualization for all disciplines

const EmptyTableCell = styled(MuiTableCell)(() => ({
  border: "none",
}));

const TableCell = styled(EmptyTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  height: 50,
  maxWidth: 100,
}));

const TableBody = styled(MuiTableBody)(({ theme }) => ({
  ...theme.typography.body2,
}));

function TimeAllocationTable(): JSX.Element {
  const theme = useTheme();
  const colors = {
    backgroundColor: {
      header:
        theme.palette.mode === "dark"
          ? theme.palette.info.dark
          : theme.palette.info.light,
      ux: theme.palette.mode === "dark" ? darken("#92D3E8", 0.2) : "#92D3E8",
      research:
        theme.palette.mode === "dark"
          ? theme.palette.success.dark
          : theme.palette.success.light,
      serviceDesign:
        theme.palette.mode === "dark" ? darken("#BFA7E4", 0.4) : "#BFA7E4",
      vp: theme.palette.mode === "dark" ? darken("#9DBCC6", 0.2) : "#9DBCC6",
      rowHeader:
        theme.palette.mode === "dark" ? darken("#9DBCC6", 0.3) : "#9DBCC6",
    },
  };
  return (
    <Box sx={{ width: { lg: 1250 }, mb: 8 }}>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: { lg: "visible" },
          mx: { lg: -28 },
        }}
      >
        <Table
          size="small"
          sx={{
            borderCollapse: "separate",
            borderSpacing: `${theme.spacing(1)} ${theme.spacing(1)}`,
          }}
        >
          <TableHead>
            <TableRow>
              <EmptyTableCell />
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  // ...theme.typography.caption,
                  backgroundColor: "unset",
                  // color: theme.palette.text.secondary,
                  boxShadow: "unset",
                  verticalAlign: "bottom",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <KeyboardArrowLeft />
                  To move over (and then up) you need to grow in your leadership
                  and management skills
                  <KeyboardArrowRight />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <EmptyTableCell />
              <TableCell
                align="center"
                sx={{
                  backgroundColor: colors.backgroundColor.header,
                }}
              >
                Individual Contributor
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: colors.backgroundColor.header,
                }}
              >
                Lead
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: colors.backgroundColor.header,
                }}
              >
                Management
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.rowHeader }}
              >
                % Contribution (IC vs Management)
              </TableCell>
              <TableCell align="center">
                100% Individual Contribution
                <br />
                0% People Management
              </TableCell>
              <TableCell align="center">
                50% Individual Contribution
                <br />
                50% People Management
              </TableCell>
              <TableCell align="center">
                0% Individual Contribution
                <br />
                100% People Management
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.rowHeader }}
              >
                People Management
              </TableCell>
              <TableCell align="center">Does not oversee anyone</TableCell>
              <TableCell align="center">
                May oversee a handful of people on manager&apos;s behalf or in
                concert with manager
              </TableCell>
              <TableCell align="center">
                Oversees a larger number of people
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.rowHeader }}
              >
                % Allocation (Contract vs Corporate)
              </TableCell>
              <TableCell align="center">90-100% Contract</TableCell>
              <TableCell align="center">
                80% Contract
                <br />
                20% Corporate
              </TableCell>
              <TableCell align="center">90-100% Corporate</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.rowHeader }}
              >
                Main Job Role
              </TableCell>
              <TableCell align="center">
                These are the workhorses. Without them, nothing would get done.
                They&apos;re the talent who exercise their skills.
              </TableCell>
              <TableCell align="center">
                Partners with other contract Leads and Program Directors to get
                work done on contract, ensure teams are working well together,
                and oversees strategic vision and team&apos;s work. Helps
                Directors and Managers see out their vision and supports
                adoption at the contract level. Supports Managers or Directors,
                as needed, to collaboratively create direction setting,
                articats, processes, etc.
              </TableCell>
              <TableCell align="center">
                Oversees and sets the direction for their discipline or sub
                discipline
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.rowHeader }}
              >
                Work Style
              </TableCell>
              <TableCell align="center">Doer/Executor</TableCell>
              <TableCell align="center">
                Thinker/Executor/Planner/Strategist
              </TableCell>
              <TableCell align="center">
                Thinker/Visionary/Strategist/Direction Setting
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ backgroundColor: colors.backgroundColor.rowHeader }}
              >
                Keywords
              </TableCell>
              <TableCell align="center">Curious, Creative, Flexible</TableCell>
              <TableCell align="center">
                Inspires, Creative, Strategic
              </TableCell>
              <TableCell align="center">
                Inspires, Proactive, Strategic, Creative
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export { TimeAllocationTable };
