import * as React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Divider, IconButton, Menu, MenuItem, Avatar } from "@material-ui/core";
import { useTheme, styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import CodeIcon from "@material-ui/icons/Code";
import { globalHistory } from "@reach/router";
import DesignServicesIcon from "@material-ui/icons/DesignServices";
import IntegrationInstructionsIcon from "@material-ui/icons/IntegrationInstructions";
import CategoryIcon from "@material-ui/icons/Category";
import EngineeringIcon from "@material-ui/icons/Engineering";
import { SettingsDrawer } from "../Settings";
import NavSection from "./NavSection";
import { useAuth } from "../AuthProvider";
import { Search } from "../Search";
import { Logo } from "../Logo";
import { Link } from "../Link";

const drawerWidth = 240;

const sections = [
  {
    title: "Employee Success",
    items: [
      {
        title: "Career Frameworks",
        path: "/employee-success/career-frameworks",
        matchPath: "/employee-success/career-frameworks",
        icon: <EngineeringIcon />,
        children: [
          {
            title: "Engineering",
            path: "/employee-success/career-frameworks/engineering",
            matchPath: "/employee-success/career-frameworks/engineering",
            children: [
              {
                title: "Overview",
                path: "/employee-success/career-frameworks/engineering/overview",
                matchPath:
                  "/employee-success/career-frameworks/engineering/overview",
              },
              {
                title: "Technical Path",
                path: "/employee-success/career-frameworks/engineering/technical-path",
                matchPath:
                  "/employee-success/career-frameworks/engineering/technical-path",
              },
              {
                title: "Management Path",
                path: "/employee-success/career-frameworks/engineering/management-path",
                matchPath:
                  "/employee-success/career-frameworks/engineering/management-path",
              },
            ],
          },
          {
            title: "Data Science",
            path: "/employee-success/career-frameworks/data-science",
            matchPath: "/employee-success/career-frameworks/data-science",
            children: [
              {
                title: "Overview",
                path: "/employee-success/career-frameworks/data-science/overview",
                matchPath:
                  "/employee-success/career-frameworks/data-science/overview",
              },
              {
                title: "Data Path",
                path: "/employee-success/career-frameworks/data-science/data-path",
                matchPath:
                  "/employee-success/career-frameworks/data-science/data-path",
              },
            ],
          },
          {
            title: "Human-Centered Design",
            path: "/employee-success/career-frameworks/human-centered-design",
            matchPath:
              "/employee-success/career-frameworks/human-centered-design",
            children: [
              {
                title: "Overview",
                path: "/employee-success/career-frameworks/human-centered-design/overview",
                matchPath:
                  "/employee-success/career-frameworks/human-centered-design/overview",
              },
              {
                title: "UX Design Path",
                path: "/employee-success/career-frameworks/human-centered-design/ux-design-path",
                matchPath:
                  "/employee-success/career-frameworks/human-centered-design/ux-design-path",
              },
              {
                title: "User Research Path",
                path: "/employee-success/career-frameworks/human-centered-design/user-research-path",
                matchPath:
                  "/employee-success/career-frameworks/human-centered-design/user-research-path",
              },
              {
                title: "Service Design Path",
                path: "/employee-success/career-frameworks/human-centered-design/service-design-path",
                matchPath:
                  "/employee-success/career-frameworks/human-centered-design/service-design-path",
              },
            ],
          },
          {
            title: "Product",
            path: "/employee-success/career-frameworks/product",
            matchPath: "/employee-success/career-frameworks/product",
            children: [
              {
                title: "Overview",
                path: "/employee-success/career-frameworks/product/overview",
                matchPath:
                  "/employee-success/career-frameworks/product/overview",
              },
              {
                title: "Product Path",
                path: "/employee-success/career-frameworks/product/product-path",
                matchPath:
                  "/employee-success/career-frameworks/product/product-path",
              },
              {
                title: "Management Path",
                path: "/employee-success/career-frameworks/product/management-path",
                matchPath:
                  "/employee-success/career-frameworks/product/management-path",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Community",
    items: [
      {
        title: "Engineering CoP",
        path: "/community/engineering-community-of-practice",
        matchPath: "/community/engineering-community-of-practice",
        icon: <EngineeringIcon />,
      },
      {
        title: "Product CoP",
        path: "/community/product-community-of-practice",
        matchPath: "/community/product-community-of-practice",
        icon: <CategoryIcon />,
      },
    ],
  },
  {
    title: "Insights",
    items: [
      {
        title: "Technology Radar",
        path: "/app/technology-radar",
        matchPath: "/app/technology-radar/*",
        icon: <TrackChangesIcon />,
      },
    ],
  },
  {
    title: "Guides",
    items: [
      {
        title: "HCD Methods",
        path: "/guides/hcd-methods",
        matchPath: "/guides/hcd-methods",
        icon: <DesignServicesIcon />,
        children: [
          {
            title: "How should I research...?",
            path: "/guides/hcd-methods/how-should-i-research",
            matchPath: "/guides/hcd-methods/how-should-i-research",
          },
        ],
      },
      {
        title: "DevSecOps",
        path: "/guides/devsecops",
        matchPath: "/guides/devsecops",
        icon: <AllInclusiveIcon />,
        children: [
          {
            title: "Continuous Delivery",
            path: "/guides/devsecops/cicd-pipeline-standards",
            matchPath: "/guides/devsecops/cicd-pipeline-standards",
          },
          {
            title: "Environments",
            path: "/guides/devsecops/environment-standards",
            matchPath: "/guides/devsecops/environment-standards",
          },
          {
            title: "Naming Standards",
            path: "/guides/devsecops/naming-standards",
            matchPath: "/guides/devsecops/naming-standards",
          },
          {
            title: "Feature Toggling",
            path: "/guides/devsecops/feature-toggling",
            matchPath: "/guides/devsecops/feature-toggling",
          },
        ],
      },
      {
        title: "Code Repository",
        path: "/guides/code-repository",
        matchPath: "/guides/code-repository",
        icon: <CodeIcon />,
        children: [
          {
            title: "Branching",
            path: "/guides/code-repository/branching-standards",
            matchPath: "/guides/code-repository/branching-standards",
          },
          {
            title: "Tagging",
            path: "/guides/code-repository/tagging-standards",
            matchPath: "/guides/code-repository/tagging-standards",
          },
          {
            title: "Configuration",
            path: "/guides/code-repository/configuration-standards",
            matchPath: "/guides/code-repository/configuration-standards",
          },
          {
            title: "Commit Messages",
            path: "/guides/code-repository/commit-messages",
            matchPath: "/guides/code-repository/commit-messages",
          },
          {
            title: "Code Style",
            path: "/guides/code-repository/code-style",
            matchPath: "/guides/code-repository/code-style",
          },
        ],
      },
    ],
  },
  {
    title: "Resources",
    items: [
      {
        title: "Projects",
        path: "/app/projects",
        matchPath: "/app/projects",
        icon: <IntegrationInstructionsIcon />,
      },
    ],
  },
];

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 1,
}));

const Navbar: React.FC = ({ window, children }) => {
  // const { window } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { isAuthenticated, logout, user } = useAuth();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <Box sx={{ p: 2 }}>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            // eslint-disable-next-line no-restricted-globals
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </Box>
    </div>
  );

  React.useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === "PUSH") setMobileOpen(false);
      }),
    [setMobileOpen]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <NavbarRoot position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Logo
              sx={{
                fontSize: "2em",
                width: "3em",
                height: "1em",
                color: "primary.contrastText",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            />
          </Link>
          <Box sx={{ flex: "1 1 auto" }} />
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Search />
          </Box>
          {isAuthenticated && <SettingsDrawer />}
          {isAuthenticated && user && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  sx={{
                    width: theme.spacing(4.15),
                    height: theme.spacing(4.15),
                  }}
                  src={user.avatar}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </NavbarRoot>

      {isAuthenticated && (
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default Navbar;
