import * as React from "react";
import { AuthProvider } from "./src/components/AuthProvider";
import { Provider as ReduxProvider } from "react-redux";
import store from "./src/components/state/store";
import { SettingsProvider } from "./src/components/Settings";
import { PageWrapper } from "./src/components/PageWrapper";

export const wrapRootElement = ({ element }) => {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <SettingsProvider>
          <AuthProvider>{element}</AuthProvider>
        </SettingsProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>;
};
