import * as React from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Typography } from "@material-ui/core";
import { useLocation, RouteComponentProps } from "@reach/router";
import GoogleIcon from "@material-ui/icons/Google";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { useAuth } from "../AuthProvider";
import { Logo } from "../Logo";
import { Link } from "../Link";

const Login: React.FC<RouteComponentProps> = () => {
  const { login, isAuthenticated } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  function handleClick() {
    setLoading(true);
    login(location.pathname);
  }
  return (
    <>
      <Helmet>
        <title>Login | Codex</title>
      </Helmet>
      <Box
        sx={{
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "110px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
            <Link to="/">
              <Logo />
            </Link>
          </Box>

          <Typography
            color="textSecondary"
            align="center"
            variant="body2"
            sx={{ mb: 2 }}
          >
            Please sign in to continue
          </Typography>
          {!isAuthenticated && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                variant="contained"
                size="large"
                startIcon={<GoogleIcon />}
                onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                sx={{ pl: 5, pr: 5 }}
              >
                Sign in with Google
              </LoadingButton>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Login;
