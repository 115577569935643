import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

type MetaProps = {
  title?: string;
};

const Meta: React.FC<MetaProps> = ({ title }) => {
  const data = useStaticQuery<{
    site: {
      siteMetadata: {
        siteTitle: string;
      };
    };
  }>(graphql`
    query {
      site {
        siteMetadata {
          siteTitle: title
        }
      }
    }
  `);
  const { siteTitle } = data.site.siteMetadata;
  const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  return (
    <Helmet title={pageTitle}>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,375,400,500,600,700&display=swap"
      />
    </Helmet>
  );
};

export { Meta };
