import React from "react";
import MuiLink, { LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import GatsbyLink from "./GatsbyLink";

export declare type LinkProps = MuiLinkProps & { to?: string };

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const { to, ...other } = props;
    return to ? (
      <MuiLink ref={ref} component={GatsbyLink} to={to} {...other} />
    ) : (
      <MuiLink ref={ref} {...other} />
    );
  }
);

export default Link;
